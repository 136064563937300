body {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #676161;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #fdb196
}

button {
  cursor: pointer;
}

main {
  padding: 20px;
}

nav {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.logo {
  display: flex;
}

.title {
  /* font-family: 'Patrick Hand'; */
  padding-left: 10px;
  font-family: 'Acme';
  font-size: 20px;
}

.App {
  text-align: center;
  margin: '0 auto';
}

.timer-container {
  display: flex;
  justify-content: center;
  margin: 30px;
}

.start-button {
  min-width: 90px;
  margin-top: 30px;
  margin: 10px;
  font-size: 16px;
  background-color: #fdb196;
  border: none;
  border-radius: 10px;
  padding: 10px;
  color: #fff;
}

.restart-button {
  min-width: 90px;
  margin-top: 30px;
  margin: 10px;
  font-size: 16px;
  background: none;
  border: 1px solid #fdb196;
  border-radius: 10px;
  padding: 10px;
  color: #676161;
}

button:focus, 
button:active {
  outline: none;
}
a, button {
  -webkit-tap-highlight-color: transparent;
}

.timer-options {
  display: flex;
  justify-content: center;
  padding: 30px;
}

.item {
  font-size: 18px;
  margin: 0 20px;
  padding: 10px;
  border-radius: 10px;
  min-width: 70px;
  border: 0.05em solid #fdb196;
  color: #676161;
  background-color: #fff;
}

.active {
  background-color: #fdb196;
  color: #fff;
}

.description {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.context {
  margin-left: 10px;
}

.modal {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 0 auto;
  z-index: 9;
  background-color:rgba(0,0,0, 0.2);
  width: 100%;
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  min-height: 100px;
  width: 300px;
  background-color: #fff;
  opacity: 1;

}

.credits {
  font-size: small;
  margin-top: 100px;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .item {
    font-size: 15px;
    margin: 10px;
  }
}